
.project-summary {
  text-align: justify;
}

.project-summary .content {
  overflow: auto;
}

.project-image {
  width: 40%;
  max-height: 100%;
  float: right;
  margin: 16px;
}

.project-section-image {
  width: 35%;
  float: right;
  margin: 16px;
}